
.loading_box {
    position: absolute;
    width: 200px;
    height: 200px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -100%);
    display: flex;
    justify-content: center;
    align-items: center;

    & .loader {
        width: 100px;
        padding: 16px;
        aspect-ratio: 1;
        border-radius: 50%;
        background: #25b09b;
        --_m: 
            conic-gradient(#0000 10%,#000),
            linear-gradient(#000 0 0) content-box;
        -webkit-mask: var(--_m);
                mask: var(--_m);
        -webkit-mask-composite: source-out;
                mask-composite: subtract;
        animation: l3 1s infinite linear;
    }
}

@keyframes l3 {to{transform: rotate(1turn)}}
