.popup_screen {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba($color: black, $alpha: 0.5);
  z-index: 100;
}

.common_popup_window {
  position: fixed;
  width: 500px;
  height: 400px;
  background-color: var(--palette-4-1);
  z-index: 107;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  box-shadow: -5px 5px 20px rgba($color: black, $alpha: 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup_windows {
  @extend .common_popup_window;
  animation: grow 0.5s normal;

  & .popup_children {
    width: 70%;
    height: 70%;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    animation: appear 0.5s forwards 0.5s;
  }
}

.closing_popup_window {
  @extend .common_popup_window;

  animation: shrink 0.5s forwards;

  & .popup_children {
    display: none;
  }
}

@keyframes grow {
  0% {
    width: 0px;
    height: 0px;
  }
}

@keyframes shrink {
  0% {
    width: 500px;
    height: 400px;
  }
  100% {
    width: 0px;
    height: 0px;
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


@media (max-width: 500px) {
  .common_popup_window {
    width: 420px;
  }
}