.contact_left {
  flex: 7;
  background-color: var(--palette-4-4);
}

.contact_right {
  flex: 0;

  animation: reduce 1s normal;
}

.contact_content {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  & .contact_form {
    width: 550px;
    height: 550px;
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    align-items: center;
    font-family: AvenirSemiBold;
    font-size: 13px;
    border-radius: 5px;
    box-shadow: -20px 15px 20px rgba($color: black, $alpha: 0.5);

    background-color: var(--palette-4-1);

    & .form_input:first-child {
      margin-top: 40px;
    }

    & .send_container {
      width: 75%;
      height: 100px;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 50px;
      column-gap: 20px;
      justify-content: space-between;

      & .send {
        border: none;
        width: 120px;
        height: 35px;
        border-radius: 20px;
        text-transform: uppercase;
        font-family: AvenirSemiBold;

        border: 1px solid transparent;
        background-color: var(--palette-4-select);
        color: var(--palette-4-1);

        &:hover {
          background-color: var(--palette-4-1);
          border-color: var(--palette-4-select);
          color: black;
        }

        &:disabled {
          opacity: 0.5;
          pointer-events: none;
        }
      }

      & .message_sent {
        text-transform: capitalize;
        font-family: AvenirLight;
        color: var(--palette-4-select);

        & .loading {
          width: 25px;
          height: 25px;
          border: 3px solid var(--palette-4-select);
          border-bottom-color: transparent;
          border-radius: 50%;
          margin-right: 30px;
          display: inline-block;
          box-sizing: border-box;
          animation: rotate 1s linear infinite;
        }

        & .message {
          animation: appear 0.5s linear;
        }
      }
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes reduce {
  0% {
    flex: 11;
  }
  100% {
    flex: 0;
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
}

@media (max-width: 500px) {
  .contact_content {
    & .contact_form {
      width: 80%;
      min-width: 280px;

      & .form_input {
        justify-content: center;
        align-items: center;
        font-size: 14px;
      }

      & .send_container {
        flex-direction: column;
        height: 50px;
        row-gap: 15px;
        margin-top: -10px;
        justify-content: center;
        align-items: center;

        & .send {
          width: 100%;
          min-height: 40px;
          border-radius: 5px;
        }

        & .message_sent {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          & .loading {
            transform: translate(-50%, -50%);
            margin: 0;
          }
        }
      }
    }
  }
}
