@font-face {
  font-family: "AvenirRegular";
  src:
    local("Metropolis-Regular"),
    url("./fonts/metropoli_avenir/Metropolis-Regular.otf") format("truetype");
}

@font-face {
  font-family: "AvenirBold";
  src:
    local("Metropolis-Bold"),
    url("./fonts/metropoli_avenir/Metropolis-Bold.otf") format("truetype");
}

@font-face {
  font-family: "AvenirSemiBold";
  src:
    local("Metropolis-SemiBold"),
    url("./fonts/metropoli_avenir/Metropolis-SemiBold.otf") format("truetype");
}

@font-face {
  font-family: "AvenirLight";
  src:
    local("Metropolis-Light"),
    url("./fonts/metropoli_avenir/Metropolis-Light.otf") format("truetype");
}

body {
  margin: 0;
  width: 100%;
  height: 100%;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

body::-webkit-scrollbar {
  display: none;
}

:root {
  background-color: black;

  --palette-1-1: #fff2d8;
  --palette-1-2: #ead7bb;
  --palette-1-3: #bca37f;
  --palette-1-4: #113946;
  --palette-1-text: black;
  --palette-1-select: #a084cf;

  --palette-2-1: #faf8f1;
  --palette-2-2: #faeab1;
  --palette-2-3: #e5ba73;
  --palette-2-4: #c58940;
  --palette-2-text: black;
  --palette-2-select: #a084cf;

  --palette-3-1: #c2d9ff;
  --palette-3-2: #8e8ffa;
  --palette-3-3: #7752fe;
  --palette-3-4: #190482;
  --palette-3-text: black;
  --palette-3-select: #a084cf;

  --palette-4-1: #ddf2fd;
  --palette-4-2: #9bbec8;
  --palette-4-3: #427d9d;
  --palette-4-4: #164863;
  --palette-4-text: black;
  --palette-4-select: #3876bf;

  --palette-5-1: #fcf5ed;
  --palette-5-2: #f4bf96;
  --palette-5-3: #ce5a67;
  --palette-5-4: #1f1717;
  --palette-5-text: black;
  --palette-5-select: #a084cf;
}
