.popup_quick_contact_email {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  font-family: AvenirSemiBold;
  font-size: 14px;
  width: 100%;
  justify-content: center;
  align-items: center;

  & .popup_quick_contact_email_send {
    border: 1px solid transparent;
    outline: none;
    width: 100%;
    height: 40px;
    background-color: var(--palette-4-select);
    color: var(--palette-4-1);
    border-radius: 20px;
    text-transform: uppercase;
    font-family: AvenirSemiBold;

    &:hover {
      background-color: var(--palette-4-1);
      color: black;
      border-color: var(--palette-4-select);
    }

    &:disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}
