.resume_left {
  flex: 7;
  background-color: var(--palette-4-4);
}

.resume_right {
  flex: 0;

  animation: reduce 1s normal;
}

.resume_content::-webkit-scrollbar {
  display: none;
}

.resume_content {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;

  & .download_resume {
    position: absolute;
    top: 80px;
    right: 180px;
    width: 200px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    text-decoration: none;
    border: 1px solid transparent;

    font-family: AvenirRegular;
    color: var(--palette-4-1);
    background-color: var(--palette-4-select);

    &:hover {
      background-color: var(--palette-4-1);
      color: var(--palette-4-4);
    }
  }

  & .resume_page_title {
    min-height: 150px;
    font-family: AvenirBold;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    column-gap: 5px;
    color: var(--palette-4-1);

    & .ball {
      width: 25px;
      height: 25px;
      background-color: var(--palette-4-select);
      border-radius: 100%;
    }
  }

  & .sections {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: AvenirRegular;
    row-gap: 80px;

    & .section:first-child {
      margin-top: 50px;
    }

    & .section:last-child {
      margin-bottom: 100px;
    }

    & .section {
      width: 70%;
      display: flex;
      flex-direction: column;
      row-gap: 50px;

      & .section_name {
        width: 100%;
        height: 80px;
        display: flex;
        align-items: center;
        font-size: 25px;
        font-family: AvenirSemiBold;
        color: var(--palette-4-1);
      }

      & .section_card {
        padding: 70px 50px 70px 50px;
        box-shadow: -5px 5px 10px rgba($color: black, $alpha: 0.5);
        border-radius: 5px;

        background-color: var(--palette-4-1);

        & .experience {
          display: flex;
          flex-direction: row;
          column-gap: 80px;

          & .info {
            flex: 2;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            row-gap: 10px;

            & .info_period {
              font-family: AvenirSemiBold;
              text-transform: capitalize;
              font-size: 18px;
              color: var(--palette-4-select);
              text-align: center;
            }

            & .info_title {
              font-family: AvenirLight;
              text-transform: uppercase;
              text-align: center;
              font-size: 15px;
            }

            & .info_place {
              font-size: 14px;
              text-align: center;
            }

            & .info_location {
              font-size: 13px;
              text-transform: lowercase;
              text-align: center;
            }
          }

          & .description {
            flex: 3;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: AvenirLight;
            font-size: 15px;
            text-align: center;
          }
        }

        & .award {
          display: flex;
          flex-direction: row;
          column-gap: 25px;
          align-items: center;

          & .award_image {
            width: 70px;
          }

          & .award_details {
            display: flex;
            flex-direction: column;
            row-gap: 15px;

            & .award_title {
              display: flex;
              flex-direction: row;
              font-family: AvenirSemiBold;
              font-size: 18px;
              column-gap: 10px;

              & .award_source {
                font-size: 17px;
              }
            }

            & .award_description {
              font-size: 14px;
            }
          }
        }

        & .skill_box {
          display: flex;
          justify-content: center;
          align-items: center;

          & .skills {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            column-gap: 80px;
            justify-content: center;

            & .skill {
              display: flex;
              flex-direction: row;
              align-items: center;
              column-gap: 10px;
              margin: 20px 20px 20px 20px;
              width: 150px;
              justify-content: center;

              & .skill_icon {
                width: 30px;
                height: 30px;
              }

              & .skill_name {
                display: flex;
                flex-direction: column;
                row-gap: 5px;
                font-family: AvenirRegular;
                font-size: 15px;

                & .skill_exp {
                  font-family: AvenirLights;
                  font-size: 12px;
                  color: var(--palette-4-select);
                }
              }
            }
          }
        }
      }
    }
  }
}

@keyframes reduce {
  0% {
    flex: 11;
  }
  100% {
    flex: 0;
  }
}

@media (max-width: 500px) {
  .resume_content {
    & .sections {
      & .section_name {
        border-radius: 5px;
        justify-content: center;
        border: 5px solid var(--palette-4-1);
      }

      & .section:first-child {
        margin-top: 180px;
      }

      & .section:last-child {
        margin-bottom: 100px;
      }

      & .section {
        width: 85%;

        & .section_card {
          & .experience {
            flex-direction: column;
            row-gap: 20px;
          }

          & .award {
            flex-direction: column;
            row-gap: 20px;

            & .award_details {
              text-align: center;

              & .award_title {
                flex-direction: column;
                align-items: center;

                & .award_source {
                  font-size: 15px;
                  width: 80%;
                  height: 30px;
                  background-color: var(--palette-4-select);
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-radius: 5px;
                  color: var(--palette-4-1);
                  text-decoration: none;
                  margin: 20px 0 5px 0;
                  border: 2px solid transparent;

                  &:hover {
                    background-color: var(--palette-4-1);
                    color: var(--palette-4-4);
                    border-color: var(--palette-4-4);
                  }
                }

                & .award_separator {
                  display: none;
                }
              }
            }
          }
        }
      }
    }

    & .download_resume {
      top: 50px;
      right: 0px;
      left: 50%;
      transform: translate(-50%, 0);
      width: 400px;
      height: 80px;
      font-size: 20px;
    }
  }
}
