.form_input {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 75%;
  row-gap: 15px;

  & .label {
    display: flex;
    flex-direction: row;
    column-gap: 5px;

    & .label_text {
      text-transform: capitalize;
    }

    & .label_required {
      color: var(--palette-4-select);
    }
  }

  & input {
    width: calc(100% - 15px);
    height: 30px;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    padding-left: 15px;
    border-bottom: 1px solid black;
    font-family: AvenirRegular;

    background-color: transparent;

    &:hover {
      border-bottom: 1px solid var(--palette-4-select);
    }
  }
}
