.pages_common_style {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  column-gap: 20px;
  margin-right: 40px;

  & button {
    height: 40px;
    text-transform: uppercase;
    font-size: 14px;
    border: none;
    outline: none;
    text-decoration: none;
    background-color: transparent;

    font-family: AvenirLight;

    &:hover {
      color: var(--palette-4-select);
    }
  }

  & .selected_page {
    color: var(--palette-4-select);
  }
}

.App {
  width: 100%;
  height: 100%;
  min-width: 1000px;
  min-height: 1100px;
  display: flex;
  flex-direction: column;

  background-color: var(--palette-4-1);

  & header {
    position: fixed;
    top: 0;
    height: 120px;
    width: 100%;
    min-width: 1000px;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    z-index: 20;
    background-color: var(--palette-4-1);

    & .name_title {
      flex: 1;
      display: flex;
      flex-direction: row;
      column-gap: 10px;
      align-items: center;
      padding-left: 40px;

      & .name_title_text_common {
        height: 30px;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 14px;
        text-transform: uppercase;
        font-family: AvenirLight;
      }

      & .name {
        @extend .name_title_text_common;
        text-transform: capitalize;
        font-weight: bold;
        font-size: 22px;
        font-family: AvenirBold;
        display: flex;
        flex-direction: row;
        column-gap: 10px;

        & .name_text {
          flex: 1;
        }

        & .point_box {
          display: flex;
          justify-content: center;
          align-items: center;

          & .point {
            width: 20px;
            height: 20px;
            border: none;
            border-radius: 100%;

            background-color: var(--palette-4-select);
          }
        }
      }

      & .title {
        @extend .name_title_text_common;
      }

      & .separator {
        @extend .name_title_text_common;
      }
    }

    & .burger {
      display: none;
    }

    & .pages {
      @extend .pages_common_style;
    }
  }

  & footer {
    bottom: 0;
    height: 130px;
    width: 100%;
    display: flex;
    flex-direction: row;

    & .copyright {
      flex: 3;
      display: flex;
      align-items: center;

      & .value {
        margin-left: 40px;
        font-family: AvenirLight;
        font-size: 12px;
        text-align: center;
      }
    }

    & .contact {
      flex: 4;
      display: flex;
      flex-direction: row;
      column-gap: 50px;
      justify-content: end;

      & .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 10px;
        font-family: AvenirRegular;

        & .title {
          font-family: AvenirSemiBold;
          font-size: 14px;
        }

        & .value {
          font-size: 13px;
        }
      }

      & .item:last-child {
        margin-right: 80px;
      }
    }
  }
}

.AppMenu {
  @extend .App;
  position: absolute;
  min-height: 800px;
  height: 800px;
  overflow: hidden;
}

.menu_pages {
  @extend .pages_common_style;
  display: none;
}

@media (max-width: 500px) {
  .App {
    min-width: 300px;

    & header {
      min-width: 300px;
      height: 140px;

      & .name_title {
        min-width: 200px;
        flex-direction: column;
        align-items: start;
        padding: 35px 0 0 40px;

        & .name {
          max-width: 200px;
          height: 50px;
          font-size: 22px;

          & .point {
          }
        }

        & .title {
        }

        & .separator {
          display: none;
        }
      }

      & .burger {
        display: flex;
        flex: 0.5;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        row-gap: 10px;

        @keyframes grow_in {
          40% {
            height: 40px;
          }
          70% {
            width: 40px;
            height: 35px;
          }
          100% {
            width: 35px;
          }
        }

        @keyframes color_in {
          70% {
            background-color: var(--palette-4-4);
          }
          100% {
            background-color: var(--palette-4-select);
          }
        }

        & .burger_box {
          width: 35px;
          height: 35px;
          display: flex;
          flex-direction: column;
          row-gap: 5px;
          animation: grow_in 0.3s linear 1s;

          & .burger_item {
            flex: 1;
            background-color: var(--palette-4-select);
            border-radius: 5px;
            animation: color_in 0.3s linear 1s;
          }

          &:hover {
            & .burger_item {
              background-color: var(--palette-4-2);
            }
          }
        }
      }

      & .pages {
        display: none;
      }
    }

    & footer {
      height: 200px;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
      margin: 0;

      & .copyright {
        & .value {
          margin: 0;
        }
      }

      & .contact {
        width: 100%;
        justify-content: center;

        & .item {
          & .title {
            font-size: 15px;
          }

          & .value {
            font-size: 14px;
          }
        }

        & .item:last-child {
          margin: 0;
        }
      }
    }
  }

  .menu_pages {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    background-color: var(--palette-4-1);
    z-index: 200;
    flex-direction: column;
    justify-content: center;
    row-gap: 70px;
    align-items: center;

    & button {
      font-size: 25px;
    }

    & button:last-child {
      font-size: 25px;
      width: 50px;
      height: 50px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        border-color: transparent;
      }
    }
  }
}
