.main_base {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: row;
  margin-top: 120px;
  overflow: hidden;

  animation: appear 0.5s normal;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

.main_base::-webkit-scrollbar {
  display: none;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
