.aboutme_page {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.about_left {
  flex: 7;
  background-color: var(--palette-4-4);
}

.about_right {
  flex: 11;
}

.actions_common {
  flex: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;

  & .selected_action {
    color: var(--palette-4-1);
    background-color: var(--palette-4-select);
    border: 1px solid transparent;
  }

  & button {
    border: 1px solid var(--palette-4-4);
    outline: none;
    border-radius: 50px;
    text-transform: uppercase;
    padding: 10px 20px 10px 20px;
    font-size: 13px;
    font-family: AvenirSemiBold;

    background-color: var(--palette-4-1);

    &:hover {
      @extend .selected_action;
    }
  }

  & button:first-child {
    @extend .selected_action;

    &:hover {
      color: black;
      background-color: var(--palette-4-1);
      border-color: var(--palette-4-select);
    }
  }
}

.about_content {
  position: absolute;
  width: 900px;
  height: 500px;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  display: flex;
  flex-direction: row;
  border: none;

  & .card {
    flex: 6;
    display: flex;
    flex-direction: column;

    border-radius: 5px;
    box-shadow: -20px 15px 20px rgba($color: black, $alpha: 0.5);

    background-color: var(--palette-4-2);
    overflow: hidden;

    & .photo {
      width: 190px;
      border-radius: 100%;
    }

    & .picture {
      flex: 17;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    & .name {
      flex: 5;
      text-transform: capitalize;
      font-size: 30px;
      font-family: AvenirBold;
      display: flex;
      justify-content: center;
      align-items: start;

      & .text {
        width: 200px;
        text-align: center;
      }
    }

    & .underline {
      flex: 2;
      display: flex;
      justify-content: center;

      & .line {
        width: 50px;
        height: 3px;
        border-radius: 10px;
        background-color: var(--palette-4-select);
      }
    }

    & .title {
      flex: 4;
      display: flex;
      justify-content: center;
      align-items: start;
      text-transform: uppercase;
      font-size: 18px;
      font-family: AvenirLight;

      & .actions {
        @extend .actions_common;
        display: none;
      }
    }

    & .socials {
      flex: 3.5;
      display: flex;
      flex-direction: row;
      column-gap: 10px;
      justify-content: center;
      align-items: center;

      background-color: var(--palette-4-1);

      & img {
        width: 25px;
      }
    }
  }

  & .profile {
    flex: 8;
    display: flex;
    flex-direction: column;
    padding-left: 35px;

    background-color: var(--palette-4-1);

    & .welcome {
      flex: 5;
      display: flex;
      align-items: center;
      text-transform: capitalize;
      font-family: AvenirBold;
      font-size: 100px;
    }

    & .headline {
      flex: 1;
      align-items: center;
      text-transform: capitalize;
      font-family: AvenirRegular;
      font-size: 20px;
      word-wrap: break-word;
      width: 300px;
    }

    & .actions {
      @extend .actions_common;
    }

    & .bio {
      flex: 4;
      font-family: AvenirLight;
      width: 400px;
      font-size: 16px;
      display: flex;
      flex-direction: column;
      text-transform: lowercase;
    }
  }
}

@media (max-width: 500px) {
  .aboutme_page {
    flex-direction: column-reverse;
    height: 1050px;
  }

  .about_left {
    flex: 5;
    background-color: var(--palette-4-4);
  }

  .about_right {
    flex: 1;
  }

  .about_content {
    top: 30px;
    transform: translate(-50%, 0);
    flex-direction: column;
    width: 80%;
    align-items: center;
    justify-content: center;
    display: block;
    min-width: 350px;

    & .card {
      width: 100%;
      flex: 1;
      height: 500px;

      & .title {
        & .title_text {
          display: none;
        }

        & .actions {
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          flex-wrap: wrap;
          height: 90px;
          padding: 0 0 20px 0;
          column-gap: 20px;
          width: 80%;

          & button {
            font-size: 14px;
            border-color: transparent;

            &:hover {
              border-color: var(--palette-4-3);
            }
          }

          & button:first-child {
            &:hover {
              border-color: transparent;
            }
          }

          // & button:last-child {
          //   display: none;
          // }
        }
      }
    }

    & .profile {
      bottom: 0;
      margin: 70px 0 0 0;
      padding: 0;
      width: 100%;
      height: 400px;

      color: var(--palette-4-1);
      background-color: transparent;

      & .actions {
        display: none;
      }

      & .welcome {
        flex: 2;
        font-size: 70px;
      }

      & .headline {
        font-size: 18px;
        width: 80%;
      }

      & .bio {
        width: 100%;
        justify-content: center;
        text-align: justify;
      }
    }
  }
}
